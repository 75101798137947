<template>
    <span>
        <v-slide-x-transition group>
            <span v-if="prefix != null" :class="spanClass" key="a">{{ prefix }}</span>
            <span v-if="isNegated" key="b">-</span>
            <span v-for="(increment, index) in filteredIncrements" :key="index" :class="spanClass">
                {{ increment.value | toDisplayNumber }} <span class="ml-1">{{ increment.text }}</span>{{ abbreviation ? '' : increment.value != 0 ? 's' : '' }}
            </span>
        </v-slide-x-transition>
        <slot name="append" />
    </span>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'BT-Increments-Quantity-String',
    data: function() {
        return {
            increments: null,
            isLoading: false
        }
    },
    props: {
        abbreviation: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: null
        },
        measurements: {
            type: Array,
            default: null
        },
        prefix: {
            type: String,
            default: null
        },
        productID: {
            type: String,
            default: null
        },
        spanClass: {
            type: String,
            default: null
        },
        successValue: {
            type: Number,
            default: null
        },
        valueProp: {
            type: String,
            default: 'quantity'
        },
        value: null //the raw quantity
    },
    computed: {
        filteredIncrements() {
            if (this.isLengthyArray(this.items)) {
                return this.getIncrements();
            }
            else if (isNaN(this.value)) {
                return [];
            }

            var iList = [];
            var remaining = this.value >= 0 ? this.value : 0 - this.value;

            if (this.isLengthyArray(this.increments) && this.isLengthyArray(this.measurements)) {
                var l = this.increments.filter(y => this.measurements.some(m => m.id == y.measurementID) && y.productID == this.productID);

                l.sort(firstBy(x => x.units, 'desc'));

                l.forEach(increment => {
                    if (increment.units <= remaining) {
                        var m = this.measurements.find(y => y.id == increment.measurementID);
                        if (m != null) {
                            var tRemaining = (remaining % increment.units);
                            
                            iList.push({
                                text: this.abbreviation ? m.abbreviation : m.measurementName,
                                value: (remaining - tRemaining) / increment.units,
                                unitValue: (this.value - remaining)
                            })

                            remaining = tRemaining;
                        }
                    }
                })
            }
            
            if (remaining > 0) {
                iList.push({
                    text: this.abbreviation ? 'u' : 'Unit',
                    value: remaining,
                    unitValue: remaining
                })
                // if (iList.length > 0) {
                //     iList.push({
                //         text: this.abbreviation ? 'u' : 'Unit',
                //         value: remaining,
                //         unitValue: remaining
                //     })
                // }
                // else {
                //     iList.push({
                //         text: null,
                //         value: remaining,
                //         unitValue: remaining
                //     })
                // }
            }

            iList.sort(firstBy(x => x.unitValue, 'desc'));

            return iList;
        },
        isNegated() {
            return this.value != null && this.value < 0;
        }
    },
    async mounted() {
        if (this.increments == null) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                this.increments = await this.$BlitzIt.store.getAll('stock-increments');
            }
            finally {
                this.isLoading = false;
            }
        }
    },
    methods: {
        getIncrements() {
            var incList = [];

            this.items.forEach(item => {
                var qty = item[this.valueProp];
                var remaining = qty >= 0 ? qty : 0 - qty;
                
                if (this.isLengthyArray(this.increments) && this.isLengthyArray(this.measurements)) {
                    var l = this.increments.filter(y => this.measurements.some(m => m.id == y.measurementID) && y.productID == item.productID);

                    l.sort(firstBy(x => x.units, 'desc'));

                    l.forEach(increment => {
                        if (increment.units <= remaining) {
                            var m = this.measurements.find(y => y.id == increment.measurementID);
                            if (m != null) {
                                var tRemaining = (remaining % increment.units);
                                var txt = this.abbreviation ? m.abbreviation : m.measurementName;
                                var v = (remaining - tRemaining) / increment.units;
                                var existing = incList.find(x => x.text == txt);

                                if (existing == null) {
                                    existing = {
                                        text: txt,
                                        value: v,
                                        unitValue: v * increment.units //(qty - remaining)
                                    }
                                    incList.push(existing);
                                }
                                else {
                                    existing.value += v;
                                    existing.unitValue += (v * increment.units); //(qty - remaining);
                                }

                                remaining = tRemaining;
                            }
                        }
                    })
                }

                if (remaining > 0) {
                
                    var exist = incList.find(x => x.text == (this.abbreviation ? 'u' : 'Unit'));
                    if (exist != null) {
                        exist.value += remaining;
                        exist.unitValue += remaining;
                    }
                    else {
                        incList.push({
                            text: this.abbreviation ? 'u' : 'Unit',
                            value: remaining,
                            unitValue: remaining
                        })
                    }
                }
            })

            return incList;
        }
    }
}
</script>